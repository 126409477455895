import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import {
  ListViewProvider,
  useListView,
} from "../../modules/apps/user-management/users-list/core/ListViewProvider";
import { QueryRequestProvider } from "../../modules/apps/user-management/users-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "../../modules/apps/user-management/users-list/core/QueryResponseProvider";
import { UsersListHeader } from "../../modules/apps/user-management/users-list/components/header/UsersListHeader";
import { UsersTable } from "./Table";
// import {UserEditModal} from './user-edit-modal/UserEditModal'
import { KTCard } from "../../../_metronic/helpers";
import { makeGetAuthApiCall } from "../../../apiConfig/Instance";
import { useEffect, useState } from "react";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Submitted Forms",
    path: "/formlisting",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const UsersList = ({ list }) => {
  return (
    <>
      <KTCard>
        <UsersTable list={list} />
      </KTCard>
    </>
  );
};

const UsersListWrapper = ({ list }) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList list={list} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

const FormsListPage = () => {
  const [formListing, setformListing] = useState<any>(null);

  useEffect(() => {
    getFormListing();
  }, []);

  const getFormListing = () => {
    makeGetAuthApiCall("getSubmission")
      .then((res) => {
        if (res.status) {
          setformListing(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return <UsersListWrapper list={formListing} />;
};

export default FormsListPage;
