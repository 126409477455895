/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeGetAuthApiCall } from "../../../apiConfig/Instance";
import { KTCard, KTCardBody, KTIcon } from "../../../_metronic/helpers";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";

const FormView: React.FC = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState<any>(null); // Change the type accordingly
  const [csvData, setCsvData] = useState<any[]>([]);
  useEffect(() => {
    GetFormDetail();
  }, []);
  const renderAccordionSection = (prefix, title) => {
    return (
      <div key={prefix} className="mt-4">
        {/* <strong style={{ fontSize: 14 }}>{title}</strong> */}
        {formData &&
          formData?.feedback.map((item, index) => {
            if (item.question.slice(0, 2) === prefix) {
              return (
                <div key={item._id} >                  
                <div className="question"><strong><span className="iconquestion">Q.</span> {item.question.slice(2)}</strong></div>
                  <div className="answer">
                  <span>Ans. {item.answer}</span>
                </div>
              </div>
              );
            }
            return null;
          })}
      </div>
    );
  };
  
  const sections = [
    { prefix: "SH", title: "Symptoms & History" },
    { prefix: "MH", title: "Medical History" },
    { prefix: "LH", title: "Lifestyle & Habits" },
    { prefix: "SM", title: "Safety & Monitoring" },
    { prefix: "OC", title: "Other Concerns" },
    // Add more sections as needed
  ];
  
  const renderedSections = sections.map((section) => {
    return renderAccordionSection(section.prefix, section.title);
  });
  const GetFormDetail = () => {
    makeGetAuthApiCall("submissionDetail", { formID: id })
      .then((res) => {
        setFormData(res.data);
        let object = { ...res.data };
        let newObject = {};
        let headerArray: any[] = [];
        let arr: any[] = [];
        Object.keys(object).map((key) => {
          if (Array.isArray(object[key])) {
            object[key].map((item) => {
              newObject[item.question] = item.answer;
              headerArray.push(item.question);
            });
          } else {
            if (key === "signature") {
              newObject[key] = process.env.REACT_APP_API_URL + object[key];
            } else {
              newObject[key] = object[key];
            }
            headerArray.push(key);
          }
        });
        arr.push(headerArray);
        arr.push(Object.values(newObject));
        setCsvData(arr);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <KTCard

    // style={{ padding: 20, borderRadius: 8, boxShadow: "0 0 2px" }}
    >
      <KTCardBody>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <strong style={{ fontSize: 14 }}>Full Name:</strong>{" "}
            {formData.fullName} <div className="px-2 d-inline">|</div>
            <strong className="ps-2" style={{ fontSize: 14 }}>
              DOB:{" "}
            </strong>
            {moment(formData.DOB).format("DD/MM/YYYY")}
            <div className="px-2 d-inline">|</div>
            <strong style={{ fontSize: 14 }}>Age: </strong>
            {formData.age}
          </div>
          <div>
            <CSVLink
              filename={`${formData.fullName}-form-feeback.csv`}
              data={csvData}
              className="btn btn-light btn-active-light-primary btn-sm"
            >
              Export
              <KTIcon iconName="file" className="fs-5 ms-2" />
            </CSVLink>
          </div>
        </div>
        <div className="mt-2">
          <strong style={{ fontSize: 14 }}>Submitted At: </strong>
          {moment(formData.createdAt).format("DD/MM/YYYY hh:mm A")}
        </div>
        <Accordion>
<div style={{marginTop:10,marginBottom:10 }}>
<strong style={{ fontSize: 14 }}>Feedback:</strong>
</div>
    {renderedSections.map((section, index) => (
      <Accordion.Item key={index} eventKey={`${index}`}>
        <Accordion.Header>
          <strong>{index + 1}. {sections[index].title}</strong>
        </Accordion.Header>
        <Accordion.Body>{section}</Accordion.Body>
      </Accordion.Item>
    ))}
  </Accordion>
        <strong className="d-block my-3" style={{ fontSize: 14 }}>
          Signature
        </strong>
        <img
          src={process.env.REACT_APP_API_URL + formData.signature}
          alt="Signature"
          style={{
            width: 400,
            height: 200,
            borderWidth: 1,
            borderRadius: 12,
            borderColor: "white",
          }}
        />
      </KTCardBody>
    </KTCard>
  );
};

export default FormView;
