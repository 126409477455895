import {useAuth} from '../../../../app/modules/auth'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, Search} from '../../../partials'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const {currentUser} = useAuth()

  return (
    <>
      {/*begin::User*/}
      {/* <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'> */}
        {/*begin::Symbol*/}
        {/* <div className='symbol symbol-50px'>
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div> */}
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        
        {/*end::Wrapper*/}
      {/* </div> */}
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/* <div className='aside-search py-5'> */}
        {/* <?php Theme::getView('partials/search/_inline', array(
        'class' => 'w-100',
        'menu-placement' => 'bottom-start',
        'responsive' => 'false'
    ))?> */}
        {/* <Search /> */}
      {/* </div> */}
      {/*end::Aside search*/}
    </>
  )
}

export {AsideToolbar}
