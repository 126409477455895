// @ts-nocheck
import { Column } from "react-table";
import { UserInfoCell } from "../../modules/apps/user-management/users-list/table/columns/UserInfoCell";
import { UserActionsCell } from "../../modules/apps/user-management/users-list/table/columns/UserActionsCell";
import { UserCustomHeader } from "../../modules/apps/user-management/users-list/table/columns/UserCustomHeader";
import moment from "moment";
import { KTIcon } from "../../../_metronic/helpers";
import { Link } from "react-router-dom";

const listColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Name"
        className="min-w-125px"
      />
    ),
    id: "fullName",
    Cell: ({ ...props }) => (
      <a href="#" className="text-gray-800 text-hover-primary mb-1">
        {props.data[props.row.index].fullName}
      </a>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Age"
        className="min-w-125px"
      />
    ),
    id: "age",
    // Cell: ({ ...props }) => (
    //   <a href="#" className="text-gray-800 text-hover-primary mb-1">
    //     {props.data[props.row.index].age}
    //   </a>
    // ),
    accessor: "age",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DOB"
        className="min-w-125px"
      />
    ),
    id: "dob",
    Cell: ({ ...props }) => (
      <a href="#" className="text-gray-600 text-hover-primary mb-1">
        {moment(props.data[props.row.index].DOB).format("DD/MM/YYYY")}
      </a>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Submitted on"
        className="min-w-125px"
      />
    ),
    id: "createdAt",
    Cell: ({ ...props }) => (
      <a href="#" className="text-gray-600 text-hover-primary mb-1">
        {moment(props.data[props.row.index].createdAt).format("DD/MM/YYYY")}
      </a>
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <>
        <Link
          to={"/view/" + props.data[props.row.index]._id}
          className="btn btn-light btn-active-light-primary btn-sm"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          View
          <KTIcon iconName="arrow-right" className="fs-5 m-0" />
        </Link>
      </>
    ),
  },
];

export { listColumns };
