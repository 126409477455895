import axios from "axios";

const nonAuth = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://13.48.17.61/"
      : "https://api.vgor.co/",
});
const AuthInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://13.48.17.61/"
      : "https://api.vgor.co/",
});

// Set the token in the headers using an interceptor
AuthInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Retrieve the token from storage (e.g., localStorage)
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const makePostAPiCall = (url, params) => {
  return new Promise((resolve, reject) => {
    nonAuth
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const makeGetAuthApiCall = (url, params) => {
  return new Promise((resolve, reject) => {
    AuthInstance.post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
